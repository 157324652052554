import React from 'react'
import { Link } from 'gatsby'
import Layout from '../components/layout'
import Card from '../components/Card'

import Wave from '../components/Wave'

const IndexPage = () => (
  <Layout>
    <div className="Hero">
      <div className="HeroGroup">
        <img src={require('../images/profile.jpg')} width="30" id="profile" />
        <h1>Victor Jaquez</h1>
        <p>
          I have a passion to build and learn. My work and personal projects
          have ranged from software development, product design,and digital
          marketing. <br /> I am a forward thinker, seeker, great communicator
          and an emphatic listener mixed with a great entrepreneurial mindset.
        </p>

        <h2>Skills</h2>
        <hr />
        <div className="Logos">
          <img src={require('../images/javascript.svg')} x />
          <img src={require('../images/react.svg')} />
          <img src={require('../images/redux.png')} />
          <img src={require('../images/nodejs.svg')} />
          <img src={require('../images/mongodb.svg')} />
          <img src={require('../images/ruby.svg')} />
          <img src={require('../images/rails.svg')} />
          <img src={require('../images/angular.svg')} />
          <img src={require('../images/html5.svg')} />
          <img src={require('../images/css3.svg')} />
          <img src={require('../images/sass.svg')} />
          <img src={require('../images/logo-figma.png')} />
          <img src={require('../images/logo-sketch.png')} />
        </div>
      </div>
    </div>
    <Wave />
    <div className="Cards">
      <h2>Projects - Work</h2>
      <div className="CardGroup">
        <Card
          title="Avail"
          text="Crypto Wallet"
          image={require('../images/github.png')}
          link="https://availwallet.herokuapp.com/"
        />
        <Card
          title="VroomSquad"
          text="Online car marketplace"
          image={require('../images/vroomsquad.png')}
          link="https://vroomsquad.com/"
        />
        <Card
          title="Taskit"
          text="Crowdsource yours tasks"
          image={require('../images/taskit.png')}
          link="http://taskit-app.herokuapp.com/"
        />
        <Card
          title="MusicMood"
          text="Post status updates with your mood"
          image={require('../images/musicmood.png')}
          link="https://music-mood-app.herokuapp.com"
        />
      </div>
    </div>
  </Layout>
)

export default IndexPage
