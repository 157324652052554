import React from 'react'
import './Card.css'

const Card = props => (
  <div className="Card">
    <div>
      <h3>{props.title}</h3>
      <p>{props.text}</p>
    </div>

    <a href={`${props.link}`} />
  </div>
)

export default Card
